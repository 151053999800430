import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IEodInventoryReport extends IBaseEntity {
    id: number;
    productName: string;
    productCode: string;
    routeName: string;
    reportedAt: number;
    reportedBy: string;
    damaged: boolean;
    beginQuantity: number;
    loadedQuantity: number;
    deliveredQuantity: number;
    transferredQuantity: number;
    salesQuantity: number;
    returnQuantity: number;
    unloadedQuantity: number;
    endQuantity: number;
    total: number;
}

export class EodInventoryReport extends BaseEntity implements IEodInventoryReport {
    public id: number;
    public productName: string;
    public productCode: string;
    public routeName: string;
    public reportedAt: number;
    public reportedBy: string;
    public damaged: boolean;
    public beginQuantity: number;
    public loadedQuantity: number;
    public deliveredQuantity: number;
    public transferredQuantity: number;
    public salesQuantity: number;
    public returnQuantity: number;
    public unloadedQuantity: number;
    public endQuantity: number;
    public total: number;

    constructor(eodInventoryReport?) {
        super();
        if (eodInventoryReport) {
            this.id = eodInventoryReport.id;
            this.productCode = eodInventoryReport.productCode;
            this.productName = eodInventoryReport.productName;
            this.routeName = eodInventoryReport.routeName;
            this.reportedAt = eodInventoryReport.reportedAt;
            this.reportedBy = eodInventoryReport.reportedBy;
            this.damaged = eodInventoryReport.damaged;
            this.beginQuantity = eodInventoryReport.beginQuantity;
            this.loadedQuantity = eodInventoryReport.loadedQuantity;
            this.deliveredQuantity = eodInventoryReport.deliveredQuantity;
            this.transferredQuantity = eodInventoryReport.transferredQuantity;
            this.salesQuantity = eodInventoryReport.salesQuantity;
            this.returnQuantity = eodInventoryReport.returnQuantity;
            this.unloadedQuantity = eodInventoryReport.unloadedQuantity;
            this.endQuantity = eodInventoryReport.endQuantity;
            this.total = eodInventoryReport.total;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): IBaseEntity {
        return new EodInventoryReport(this);
    }
}
